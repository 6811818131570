import React from 'react';
import './styles.css'; // Ensure styles are imported

//<h2 className="question-title">{question.title}</h2>

function Question({ question, onAnswerSelect }) {
  return (
    <div className="quiz-container">
      <div>
        
        <p className="question-prompt">{question.prompt}</p>
        <div>
          {Array.from({ length: question.range.max }, (_, i) => i + 1).map(value => (
            <button
              key={value}
              className="btn answer-btn"
              onClick={() => onAnswerSelect(value, question.category)}>
              {value}
            </button>
          ))}
        </div>
      </div>
      <div className="value-descriptions">
        <div className="min-value">
          <strong>{question.range.min} being:</strong>
          <p>{question.extremeValues.minValueAnswer}</p>
        </div>
        <div className="max-value">
          <strong>{question.range.max} being:</strong>
          <p>{question.extremeValues.maxValueAnswer}</p>
        </div>
      </div>
    </div>
  );
}

export default Question;
