import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Quiz from './Quiz';
// Import your quizData from a separate file or however you have it stored
import PrivacyPolicy from './PrivacyPolicy';
import quizData from './quizData';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Quiz quizData={quizData} />} />
          <Route path="/noria" element={<Quiz quizData={quizData} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;