import React, { useState } from 'react';
import Question from './Question';
import Results from './Results';
import './styles.css';

function Quiz({ quizData }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1); // Start with -1 to indicate not started
  const [scores, setScores] = useState({});
  const [quizStarted, setQuizStarted] = useState(false); // New state to track if the quiz has started
  const [animationClass, setAnimationClass] = useState('');
  //const [emailSubmitted, setEmailSubmitted] = useState(false);

  const startQuiz = () => {
    setAnimationClass('fade-out');
    setTimeout(() => {
      setQuizStarted(true);
      setCurrentQuestionIndex(0); // Begin with the first question
      setAnimationClass('fade-in');
    }, 500); // This timeout duration should match the fade-out animation duration
  };

  const handleAnswerSelect = (score, category) => {
    setScores(prevScores => ({
      ...prevScores,
      [category]: [...(prevScores[category] || []), score],
    }));
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < quizData.quiz.questions.length) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      setCurrentQuestionIndex(-1); // Indicates quiz completion
    }
  };
  //<script async src="https://www.googletagmanager.com/gtag/js?id=AW-684082633"></script>
  //window.gtag('config', 'AW-684082633');
// Inside the Quiz component's return statement
if (!quizStarted) {
  // Quiz start page
  return (
    <div className="quiz-container">


<script async src="https://www.googletagmanager.com/gtag/js?id=AW-684082633"></script>




      <div className="quiz-start">
        <img src={quizData.quiz.logo_url} alt="Quiz Logo" className="mb-3" />
        <h1 className="quiz-title">{quizData.quiz.title}</h1>
        <p className="quiz-subtitle">{quizData.quiz.subtitle}</p>
        <button className="btn quiz-start-btn" onClick={startQuiz}>Start Quiz</button>
        
      </div>
    </div>
  );
} else if (currentQuestionIndex >= 0) {
  // Show the current question
  return (
    <div className={`quiz-content ${animationClass}`}>
      <Question question={quizData.quiz.questions[currentQuestionIndex]} onAnswerSelect={handleAnswerSelect} />
      
    </div>
  );
/*
} else if (!emailSubmitted) {
  // Show the email form if the quiz has ended and email has not been submitted
  //setEmailSubmitted(true);
  return (
    <div className={`quiz-content ${animationClass}`}>
      <EmailForm onEmailSubmit={() => setEmailSubmitted(true)} />
    </div>
  );
*/
} else {
  // Show the results if the quiz has ended and email has been submitted
  //window.gtag('event', 'conversion', {'send_to': 'AW-684082633/UNQ0CK7i48cZEMmLmcYC'});
  return (
    <div className={`quiz-content ${animationClass}`}>
      <Results scores={scores} quizData={quizData} />
    </div>
  );
}
}
export default Quiz;
