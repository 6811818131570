const quizData = {
    quiz: {
        "title": "Welcome to Noria's Burnout Assessment",
        'subtitle': "Discover What Depletes Your Innerwell",
        "logo_url": "https://images.squarespace-cdn.com/content/v1/62991701124f0a3e2cd69968/bc54da16-0ff1-4575-ad46-92e22eda6deb/Circle.gif",
        "response_url": "https://www.dataxtractr.com/noria_quiz_email",
        "questions": [
            {
            "questionNumber": 1,
            "questionType": "Rating Scale",
            "title": "Lack of Boundaries",
            "category": "Lack of Boundaries",
            "prompt": "On a scale of 1-10, please rate your relationship with work boundaries:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I consistently work beyond my normal work hours & do not have clear work/life boundaries.",
                "maxValueAnswer": "I have very clear boundaries with work and prioritize my own self-care and needs over work obligations."
            }
            },
            {
            "questionNumber": 2,
            "questionType": "Rating Scale",
            "title": "Perfectionism",
            "category": "Perfectionism",
            "prompt": "On a scale of  1-10, please rate your tendency towards perfection",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I’m uncomfortable with submitting work that is anything less than perfect, or close to it and will work beyond normal work hours to achieve the quality of work I desire.",
                "maxValueAnswer": "I am able to do a 'good enough job' in order to maintain healthy work boundaries and time for myself."
            }
            },
            {
            "questionNumber": 3,
            "questionType": "Rating Scale",
            "title": "Low Resiliency (rolling with difficult times)",
            "category": "Lack of Resiliency",
            "prompt": "On a scale of 1-10, please rate your ability to cope with stressors at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "Work difficulties often affect me in a big, negative way (e.g. when I am faced with a challenge at work, I take it very personally and have a hard time “leaving it at work” or  I avoid the problem or give up).",
                "maxValueAnswer": "I roll with big challenges and view them as inevitable and part of my growth."
            }
            },
            {
            "questionNumber": 4,
            "questionType": "Rating Scale",
            "title": "Unmanageable Workload",
            "category": "Overwhelming Workload",
            "prompt": "On a scale of 1-10, please rate the level of your workload:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "My workload feels insurmountable.",
                "maxValueAnswer": "My workload is manageable, I can get my work done during work hours."
            }
            },
            {
            "questionNumber": 5,
            "questionType": "Rating Scale",
            "title": "Lack of Autonomy",
            "category": "Lack of Autonomy",
            "prompt":  "On a scale of 1-10, please rate your experience with workplace autonomy:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I feel controlled and micromanaged when I am at work.",
                "maxValueAnswer": "I have control over my day-to-day existence at work (e.g. I take breaks when I need them, my schedule is adaptable, I have the freedom to work the way I want)."
            }
            },
            {
            "questionNumber": 6,
            "questionType": "Rating Scale",
            "title": "Lack of Recognition",
            "category": "Lack of Recognition",
            "prompt": "On a scale of 1-10, please rate your experience with recognition at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I feel like I am not valued or recognized for the good work I do; others get rewarded over me. I feel invisible.",
                "maxValueAnswer": " I am valued, rewarded, and fully recognized for the work that I do. I feel seen and appreciated."
            }
            },
            {
            "questionNumber": 7,
            "questionType": "Rating Scale",
            "title": "Poor Relationships",
            "category": "Sub-par Relationships",
            "prompt": "On a scale of 1-10, please rate the quality of your relationships at work:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I do not have a strong connection or trust/rapport with my team members or leaders.",
                "maxValueAnswer": "I have stellar relationships with the people I work with and feel a sense of belonging and trust."
            }
            },
            {
            "questionNumber": 8,
            "questionType": "Rating Scale",
            "title": "Misalignment of Values",
            "category": "Misalignment of Values",
            "prompt": "On a scale of 1-10, please rate how well your values are aligned with the work that you do:",
            "range": {
                "min": 1,
                "max": 10
            },
            "extremeValues": {
                "minValueAnswer": "I do not derive any meaning or purpose from the work that I do; it is simply a job I have to do.",
                "maxValueAnswer": "While at work, I am aligned with my values and connected to a sense of meaning and purpose. I am contributing to something bigger than myself."
            }
            }
        ]

    },
    categoryResponses: {

        "categoryResponse": {
            "Lack of Boundaries": {
              "explanation": "You will benefit from taking a good hard look at all the ways your work might be creeping into other aspects of your life. Are you constantly available after hours and during your time off? Do you say yes when you want to say no? How can you better contain your work so that you have time to rest, refuel, and do the things you love?",
              "suggestions": [
                "Practice saying 'no' to additional requests for your time. View it as an experiment: what was the outcome? did it corroborate your fears? how did you feel?",
                "Create a transition ritual. Write out lingering to-dos for the next day, light a candle, go for a walk, listen to music, play with your kids. Find an activity that will signal the end of the work day and commit to not working beyond your normal hours.",
                "Limit your technology. Turn off notifications at a certain hour and remove messaging apps and email from your phone. Keep your phone out of the bedroom at night."
              ]
            },
            "Perfectionism": {
              "explanation": "The drive to get things 'just right' might be holding you back from being your best self. Most high performers have some level of perfectionism, this trait has allowed you to excel, but it can also cause you to be very hard on yourself when you make a mistake. You are meticulous with your work and prefer to burn the midnight oil to avoid the F word: failure. However, you produce your best work when you are well-rested and functioning at full capacity.",
              "suggestions": [
                "Pay attention to when your perfectionist qualities come out and observe without judgment. How do you act when you are in perfection mode? how do you feel in body and mind? how might this behavior help, harm, or hinder you?",
                "Experiment with being 'good enough.' Sit with the discomfort of doing something below the high bar you normally set for yourself. Practice delegating and letting go. See what happens - excellence can still be achieved."
              ]
            },
            "Lack of Resiliency": {
              "explanation": "Resiliency is a muscle that can be built. You may feel overwhelmed by changes or challenges at work and overcome by emotions. To an extent, this is understandable, but be mindful of when your feelings of overwhelm may be holding you back and keeping you stuck in the same place.",
              "suggestions": [
                "Notice when sensations of overwhelm, frustration, or sadness are taking over. Acknowledge and validate them, there is a reason they are showing up. Let the feeling move through you.",
                "Engage in acts of self-care. What is something that would help you feel more grounded and in control? Exercise, meditation, socializing with friends, a warm bath, etc. When you ground and regulate your nervous system you are better able to manage challenges.",
                "Reframe the disappointment or challenge. What did you learn or gain from this experience? What is the bigger meaning behind this setback? How does it connect to your purpose?"
              ]
            },
            "Overwhelming Workload": {
              "explanation": "Your plate is full - and then some. It's time to step off the hamster wheel to prioritize and delegate. What can be taken off your plate and who can take it? What led you to this state in the first place? Take a moment to assess where you are and how you got here. What needs to change going forward?",
              "suggestions": [
                "Talk to your manager about your workload. Share how it is impacting you personally and your ability to perform. Ask for support in problem-solving a solution that works for both of you.",
                "Practice delegating. Assess what can be taken off your plate and invest an hour to create a delegation plan.",
                "Experiment with saying 'no.' Have a response ready to go when someone asks for more of your time: 'I am working on three important tasks this week and unfortunately do not have time to take on your request/project/task. I will follow up at the end of next week once I have more space.'"
              ]
            },
            "Lack of Autonomy": {
              "explanation": "You may be feeling like you don't have control over your schedule, your decisions, or the work that you do. This could be for a variety of reasons: a general lack of flexibility at your job, a packed schedule, or micromanagement. This is highly personal but here are some suggestions to start addressing your lack of autonomy:",
              "suggestions": [
                "Spend time reflecting on what is creating a feeling of restriction for you. How does this contribute to your stress?",
                "How might you add more spaciousness, independence, and freedom into your work life? What would it take?",
                "Communicate your needs. Whether it be to your manager, partner, or simply to yourself. Say out loud what you need and why. Try to find a compromise that would allow you to feel less depleted."
              ]
            },
            "Lack of Recognition": {
              "explanation": "You might be feeling undervalued, or worse, invisible at your current job. Resolving this root cause may take some courageous conversations - but it's worth it. Sometimes, recognition is not part of the work culture. Sometimes, a manager is unaware of or unskilled at providing consistent acknowledgment. Sometimes, you may find yourself in a work environment that is competitive or toxic.",
              "suggestions": [
                "Reflect on what is going on and how it impacts your mental wellbeing. Is this a culture problem? Manager? What do you need to feel more recognized for your work and who can you talk to?",
                "Prepare to have a brave conversation where you ask for what you need. Highlight your accomplishments and how they have gone unnoticed. Share how you feel and how the lack of recognition impacts your ability to continue doing hard work."
              ]
            },
            "Sub-par Relationships": {
              "explanation": "Healthy relationships and a strong community are key factors that mitigate stress, especially within the workplace. If you are feeling lonely, isolated, or ostracized this can have a significant and negative impact on your mental wellbeing.",
              "suggestions": [
                "Reflect on what is going on in your current situation. Has remote work impacted your ability to connect with others? Do you feel like you don't belong or that you can't be yourself around your coworkers? What is keeping you from feeling a sense of community and belonging while at work?",
                "Think about what you can do personally to feel more connected to the people you work with. Schedule informal coffee chats to connect with people you'd like to build a relationship with, create a non-work related group to discuss shared interests such as books, movies, recipes, etc., talk to your manager about your feelings of disconnect, and suggest investing in creating team cohesion.",
                "Invest in building community outside of work. Nurture your existing relationships and create new relationships through volunteer activities, joining a church, starting a parent meet-up, joining a sports team, or taking a class."
              ]
            },
            "Misalignment of Values": {
              "explanation": "Your values inform your beliefs, passions, and what you care about deeply. While you do not need to work at a job that is 100% aligned with your values, a complete mismatch can cause internal tension and stress. Your job also does not need to fulfill your life's purpose, but if that’s the case, you must find purpose outside of work. If you are working a job and living a life misaligned with your values, this can be incredibly depleting.",
              "suggestions": [
                "Reflect on what your values are. Make a list of your top five.",
                "Identify the values of your company and the bigger mission/purpose you are contributing to by working there. Does this align with your values and purpose?",
                "How might you better align your work or life with your values? What is one change you can make?"
              ]
            }
          },
        "lastResponse": "To learn more, check out our course ",
        "lastResponseLink": "https://hellonoria.com/individuals"      
    }
  };
  
  export default quizData;